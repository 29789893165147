<template>
  <div v-if="doctor.examSettings">
    <v-container>
      <h1>My Exam</h1>
      <v-alert dense type="error" dismissible v-if="error">
        {{ error }}</v-alert
      >
      <v-stepper non-linear v-model="e1" class="mt-4">
        <v-stepper-header>
          <v-stepper-step
            v-if="doctor.examSettings.inviteRelative"
            editable
            step="1"
          >
            Invite
          </v-stepper-step>

          <v-divider v-if="doctor.examSettings.inviteRelative"></v-divider>

          <v-stepper-step v-if="doctor.examSettings.vitals" editable step="2">
            Vitals
          </v-stepper-step>

          <v-divider v-if="doctor.examSettings.vitals"></v-divider>

          <v-stepper-step
            v-if="doctor.examSettings.medicalHistory"
            editable
            step="3"
          >
            Medical History
          </v-stepper-step>

          <v-divider v-if="doctor.examSettings.medicalHistory"></v-divider>

          <v-stepper-step v-if="doctor.examSettings.symptoms" editable step="4">
            Symptoms
          </v-stepper-step>

          <v-divider v-if="doctor.examSettings.symptoms"></v-divider>

          <v-stepper-step
            v-if="doctor.examSettings.medicalDocuments"
            editable
            step="5"
          >
            Medical Documents
          </v-stepper-step>

          <v-divider v-if="doctor.examSettings.medicalDocuments"></v-divider>

          <v-stepper-step
            v-if="doctor.examSettings.medications"
            editable
            step="6"
          >
            Medications
          </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content v-if="doctor.examSettings.inviteRelative" step="1">
            <v-card class="mb-12" flat>
              <v-card-title>
                Invite
              </v-card-title>
              <v-card-text>
                Do you need anyone else to attend?

                <v-form ref="inviteform" v-model="validInvite" lazy-validation>
                  <v-text-field
                    class="mt-4"
                    v-model="invite.fname"
                    label="First Name"
                    dense
                    :rules="rules"
                    outlined
                    rounded
                    required
                    hide-details="hint"
                  ></v-text-field>

                  <v-text-field
                    class="mt-4"
                    v-model="invite.lname"
                    label="Last Name"
                    dense
                    :rules="rules"
                    outlined
                    rounded
                    required
                    hide-details="hint"
                  ></v-text-field>

                  <v-text-field
                    class="mt-4"
                    v-model="invite.email"
                    label="Email"
                    dense
                    outlined
                    rounded
                    required
                    :rules="emailRules"
                    hide-details="hint"
                  ></v-text-field>
                  <v-text-field
                    class="mt-4"
                    v-model="invite.phoneNumber"
                    label="Phone Number"
                    dense
                    outlined
                    rounded
                    required
                    :rules="phoneRules"
                    hide-details="hint"
                  ></v-text-field>
                  <v-btn
                    @click="inviteRelative()"
                    :loading="loadingInvite"
                    :disabled="!validInvite"
                    class="mt-4"
                    color="primary"
                    block
                    outlined
                    rounded
                  >
                    Send Invite
                    <v-icon right>mdi-account-plus-outline</v-icon>
                  </v-btn>
                </v-form>
              </v-card-text>
              <div v-if="attendees.length > 0">
                <v-card-title>Attendees</v-card-title>

                <v-col v-for="item in attendees" :key="item.id" cols="12">
                  <v-card color="primary" dark>
                    <v-card-text class="font-weight-bold white--text">
                      {{ item.fname }} {{ item.lname }} | {{ item.email }} |
                      {{ item.phone }}
                    </v-card-text>
                  </v-card>
                </v-col>
              </div>
            </v-card>

            <div class="d-flex justify-center">
              <v-btn class="ml-2" color="primary" @click="e1 = 2">
                Next
                <v-icon right>mdi-arrow-right</v-icon>
              </v-btn>
              <v-btn
                :disabled="!appointmentInRange"
                color="primary"
                class="ml-2"
                @click="finish()"
              >
                Finish & Start Call
              </v-btn>
            </div>
          </v-stepper-content>

          <v-stepper-content v-if="doctor.examSettings.vitals" step="2">
            <v-card class="mb-12" flat>
              <v-card-title>
                Vitals
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="6">
                    <v-select
                      v-model="heightFeet"
                      :items="heightFeetSelect"
                      item-text="feet"
                      item-value="value"
                      label="Height"
                      suffix="ft"
                      hide-details="hint"
                      rounded
                      dense
                      outlined
                      return-object
                    ></v-select>
                  </v-col>

                  <v-col cols="6">
                    <v-select
                      v-model="heightInches"
                      :items="heightInchesSelect"
                      item-text="inches"
                      item-value="value"
                      label="Height"
                      suffix="in"
                      hide-details="hint"
                      rounded
                      dense
                      outlined
                      return-object
                    ></v-select>
                  </v-col>
                </v-row>

                <v-text-field
                  class="mt-4"
                  v-model="weight"
                  label="Weight"
                  suffix="lbs"
                  dense
                  outlined
                  rounded
                  hide-details="hint"
                ></v-text-field>

                <v-text-field
                  class="mt-4"
                  v-model="vitals.temperature"
                  label="Temperature"
                  :suffix="`°F`"
                  dense
                  outlined
                  rounded
                  hide-details="hint"
                ></v-text-field>

                <v-text-field
                  class="mt-4"
                  v-model="vitals.systolic"
                  label="Systolic blood pressure"
                  suffix="mmHg"
                  dense
                  outlined
                  rounded
                  hide-details="hint"
                ></v-text-field>

                <v-text-field
                  class="mt-4"
                  v-model="vitals.diastolic"
                  label="Diastolic blood pressure"
                  suffix="mmHg"
                  dense
                  outlined
                  rounded
                  hide-details="hint"
                ></v-text-field>
                <v-text-field
                  class="mt-4"
                  v-model="vitals.pulse"
                  label="Pulse"
                  suffix="bpm"
                  dense
                  outlined
                  rounded
                  hide-details="hint"
                ></v-text-field>

                <v-text-field
                  class="mt-4"
                  v-model="vitals.resprate"
                  label="Respiratory rate"
                  suffix="bths/min"
                  dense
                  outlined
                  rounded
                  hide-details="hint"
                ></v-text-field>

                <v-text-field
                  class="mt-4"
                  v-model="vitals.pulseox"
                  label="Pulse oximetry"
                  suffix="%"
                  dense
                  outlined
                  rounded
                  hide-details="hint"
                ></v-text-field>

                <v-text-field
                  class="mt-4"
                  v-model="vitals.painscale"
                  label="Pain level"
                  suffix="/10"
                  dense
                  outlined
                  rounded
                  hide-details="hint"
                ></v-text-field>
              </v-card-text>
            </v-card>

            <div class="d-flex justify-center">
              <v-btn class="mr-2" color="primary" @click="saveVitals(1)">
                <v-icon left>mdi-arrow-left</v-icon>
                Back
              </v-btn>
              <v-btn color="primary" @click="saveVitals(-1)" outlined>
                Save
              </v-btn>
              <v-btn class="ml-2" color="primary" @click="saveVitals(3)">
                Next
                <v-icon right>mdi-arrow-right</v-icon>
              </v-btn>
              <v-btn
                :disabled="!appointmentInRange"
                color="primary"
                class="ml-2"
                @click="finish()"
              >
                Finish & Start Call
              </v-btn>
            </div>
          </v-stepper-content>

          <v-stepper-content v-if="doctor.examSettings.medicalHistory" step="3">
            <v-card class="mb-12" flat>
              <v-card-title>
                Medical History
              </v-card-title>
              <v-card-text
                v-for="(item, index) in medicalHistoryQuestions"
                :key="index"
              >
                <v-textarea
                  class="mt-4"
                  v-model="medicalHistory[index]"
                  :label="medicalHistoryQuestions[index]"
                  dense
                  outlined
                  auto-grow
                  hide-details="hint"
                ></v-textarea>
              </v-card-text>
            </v-card>

            <div class="d-flex justify-center">
              <v-btn
                class="mr-2"
                color="primary"
                @click="saveMedicalHistory(2)"
              >
                <v-icon left>mdi-arrow-left</v-icon>
                Back
              </v-btn>
              <v-btn color="primary" @click="saveMedicalHistory(-1)" outlined>
                Save
              </v-btn>
              <v-btn
                class="ml-2"
                color="primary"
                @click="saveMedicalHistory(4)"
              >
                Next
                <v-icon right>mdi-arrow-right</v-icon>
              </v-btn>
              <v-btn
                :disabled="!appointmentInRange"
                color="primary"
                class="ml-2"
                @click="finish()"
              >
                Finish & Start Call
              </v-btn>
            </div>
          </v-stepper-content>

          <v-stepper-content v-if="doctor.examSettings.symptoms" step="4">
            <v-card class="mb-12" flat>
              <v-card-title>
                Symptoms
              </v-card-title>
              <v-card-text>
                <v-expansion-panels>
                  <v-expansion-panel v-for="(item, i) in symptoms" :key="i">
                    <v-expansion-panel-header class="font-weight-bold">
                      {{ item.category }}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="custom-drop">
                      <v-data-table
                        :items="item.symptoms"
                        item-key="name"
                        :headers="symptomsHeader"
                        hide-default-header
                        :items-per-page="itemsPerPage"
                        dense
                        hide-default-footer
                      >
                        <template v-slot:item.checked="{ item }">
                          <v-checkbox
                            v-model="item.checked"
                            style="float: right;"
                          ></v-checkbox>
                        </template>
                      </v-data-table>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-card-text>
            </v-card>

            <div class="d-flex justify-center">
              <v-btn class="mr-2" color="primary" @click="updateSymptoms(3)">
                <v-icon left>mdi-arrow-left</v-icon>
                Back
              </v-btn>
              <v-btn
                color="primary"
                :loading="loadingSymptoms"
                @click="updateSymptoms(-1)"
                outlined
              >
                Save
              </v-btn>
              <v-btn class="ml-2" color="primary" @click="updateSymptoms(5)">
                Next
                <v-icon right>mdi-arrow-right</v-icon>
              </v-btn>
              <v-btn
                :disabled="!appointmentInRange"
                color="primary"
                class="ml-2"
                @click="finish()"
              >
                Finish & Start Call
              </v-btn>
            </div>
          </v-stepper-content>

          <v-stepper-content
            v-if="doctor.examSettings.medicalDocuments"
            step="5"
          >
            <v-card class="mb-12" flat>
              <v-card-title>
                Upload Medical Documents
              </v-card-title>
              <v-card-text>
                <v-form ref="docsform" v-model="validDoc" lazy-validation>
                  <v-text-field
                    class="mb-4"
                    ref="medicalDocName"
                    v-model="medicalDocName"
                    label="File Name"
                    dense
                    outlined
                    rounded
                    hide-details="hint"
                  ></v-text-field>
                  <v-file-input
                    ref="medicalDocInput"
                    accept="image/*"
                    placeholder="Image File"
                    hint="Choose an image file"
                    persistent-hint
                    outlined
                    rounded
                    required
                    dense
                    prepend-icon=""
                    @change="prepareMedicalDocImage"
                  ></v-file-input>
                  <v-btn
                    @click="uploadMedicalDocFile"
                    :disabled="!medicalDocImage || !medicalDocName"
                    color="primary"
                    block
                    rounded
                    :loading="loadingDocUpload"
                    outlined
                    >Upload</v-btn
                  >
                </v-form>
              </v-card-text>
              <v-card-text>
                <v-row v-if="medicalDocs.length > 0">
                  <v-col
                    cols="6"
                    md="4"
                    v-for="file in medicalDocs"
                    :key="file.id"
                  >
                    <v-card height="100%" class="d-flex flex-column">
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          icon
                          color="red"
                          @click="deleteMedicalDoc(file.id)"
                        >
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </v-card-actions>
                      <v-img
                        :src="file.url"
                        max-height="200"
                        contain
                        class="grey darken-4"
                      ></v-img>
                      <v-card-title class="subtitle-2">
                        {{ file.fileName }}
                      </v-card-title>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>

            <div class="d-flex justify-center">
              <v-btn class="mr-2" color="primary" @click="e1 = 4">
                <v-icon left>mdi-arrow-left</v-icon>
                Back
              </v-btn>
              <v-btn class="ml-2" color="primary" @click="e1 = 6">
                Next
                <v-icon right>mdi-arrow-right</v-icon>
              </v-btn>
              <v-btn
                :disabled="!appointmentInRange"
                color="primary"
                class="ml-2"
                @click="finish()"
              >
                Finish & Start Call
              </v-btn>
            </div>
          </v-stepper-content>

          <v-stepper-content v-if="doctor.examSettings.medications" step="6">
            <v-card class="mb-12" flat>
              <v-card-title>
                Pharmacy
              </v-card-title>
              <v-card-subtitle>{{ currentPharmacy }}</v-card-subtitle>

              <v-card-text>
                <v-btn
                  v-if="!pharmaSearch"
                  color="primary"
                  class="mb-4"
                  @click="pharmaSearch = true"
                  >Change</v-btn
                >
                <v-text-field
                  v-show="pharmaSearch"
                  v-model="location"
                  label="Search Pharmacy"
                  dense
                  outlined
                  rounded
                  hide-details="hint"
                ></v-text-field>
                <v-list v-show="location" dense>
                  <v-list-item-group v-model="selectedPharmacy" color="primary">
                    <v-list-item v-for="(result, i) in searchResults" :key="i">
                      <v-list-item-content>
                        <v-list-item-title @click="setPharmacy(result)">{{
                          result.description
                        }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
                <!-- <ul>
                  <li v-for="(result, i) in searchResults" :key="i">
                    {{ result }}
                  </li>
                </ul> -->
              </v-card-text>
              <v-card-title>
                Upload Medications
              </v-card-title>
              <v-card-text>
                <v-form ref="medsform" v-model="validMeds" lazy-validation>
                  <v-text-field
                    class="mb-4"
                    ref="medicationName"
                    v-model="medicationName"
                    label="Medication Name"
                    dense
                    outlined
                    rounded
                    hide-details="hint"
                  ></v-text-field>
                  <v-btn
                    @click="uploadMedication"
                    :disabled="!medicationName"
                    color="primary"
                    block
                    rounded
                    outlined
                    >Add</v-btn
                  >
                  <v-card-subtitle class="text-center primary--text"
                    >OR</v-card-subtitle
                  >
                  <v-file-input
                    ref="medicationInput"
                    accept="image/*"
                    placeholder="Medication Photo"
                    hint="Choose an image file"
                    persistent-hint
                    outlined
                    rounded
                    required
                    dense
                    prepend-icon=""
                    @change="prepareMedicationImage"
                  ></v-file-input>
                  <v-btn
                    @click="uploadMedicationFile"
                    :disabled="!medicationImage"
                    color="primary"
                    block
                    rounded
                    :loading="loadingMedicationUpload"
                    outlined
                    >Upload</v-btn
                  >
                </v-form>
              </v-card-text>
              <v-card-title>
                Medications
              </v-card-title>
              <v-card-text>
                <v-row v-if="medicationsDocs.length > 0">
                  <v-col
                    cols="6"
                    md="4"
                    v-for="file in medicationsDocs"
                    :key="file.id"
                  >
                    <v-card height="100%" class="d-flex flex-column">
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          icon
                          color="red"
                          @click="deleteMedication(file.id)"
                        >
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </v-card-actions>
                      <v-img
                        v-if="file.url"
                        :src="file.url"
                        max-height="200"
                        contain
                        class="grey darken-4"
                      ></v-img>
                      <v-card-title class="subtitle-2">
                        {{ file.fileName }}
                      </v-card-title>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>

            <div class="d-flex justify-center">
              <v-btn class="mr-2" color="primary" @click="e1 = 5">
                <v-icon left>mdi-arrow-left</v-icon>
                Back
              </v-btn>
              <v-btn
                :disabled="!appointmentInRange"
                color="primary"
                class="ml-2"
                @click="finish()"
              >
                Finish & Start Call
              </v-btn>
            </div>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-container>
  </div>
</template>

<script>
import PatientLayout from '@/layouts/PatientLayout'
import * as fb from '@/firebase'
import moment from 'moment'
export default {
  name: 'MyExam',
  metaInfo() {
    return {
      script: [
        {
          src: `https://maps.googleapis.com/maps/api/js?key=AIzaSyAe2obJQESnmEJYiv1ElsExbqnpuK4aW_o&libraries=places`,
          async: true,
          defer: true,
          callback: () => this.MapsInit() // will declare it in methods
        }
      ]
    }
  },
  data() {
    return {
      pharmaSearch: false,
      selectedPharmacy: '',
      location: '',
      searchResults: [],
      service: null,
      e1: 1,
      error: null,
      itemsPerPage: 20,
      patientId: '',
      practiceId: '',
      appointmentId: '',
      symptomsCollection: [],
      attendees: [],
      patient: {},
      appointment: {},
      loadingInvite: false,
      validInvite: false,
      validDoc: false,
      validMeds: false,
      doctor: {},
      invite: {
        fname: '',
        lname: '',
        email: '',
        phoneNumber: ''
      },
      rules: [v => !!v || 'Required'],
      phoneRules: [
        value => !!value || 'Required.',
        value => {
          const pattern = /^\d{3}-\d{3}-\d{4}$/
          const pattern2 = /^\d{10}$/
          return (
            pattern.test(value) ||
            pattern2.test(value) ||
            'Invalid phone number'
          )
        }
      ],
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid'
      ],
      heightFeet: { feet: "5 '", value: 5 },
      heightFeetSelect: [
        { feet: "0 '", value: 0 },
        { feet: "1 '", value: 1 },
        { feet: "2 '", value: 2 },
        { feet: "3 '", value: 3 },
        { feet: "4 '", value: 4 },
        { feet: "5 '", value: 5 },
        { feet: "6 '", value: 6 },
        { feet: "7 '", value: 7 },
        { feet: "8 '", value: 8 }
      ],
      heightInches: { inches: `0 "`, value: 0 },
      heightInchesSelect: [
        { inches: `0 "`, value: 0 },
        { inches: `1 "`, value: 1 },
        { inches: `2 "`, value: 2 },
        { inches: `3 "`, value: 3 },
        { inches: `4 "`, value: 4 },
        { inches: `5 "`, value: 5 },
        { inches: `6 "`, value: 6 },
        { inches: `7 "`, value: 7 },
        { inches: `8 "`, value: 8 },
        { inches: `9 "`, value: 9 },
        { inches: `10 "`, value: 10 },
        { inches: `11 "`, value: 11 },
        { inches: `12 "`, value: 12 }
      ],
      weight: null,
      vitals: {
        painscale: null,
        pulse: null,
        pulseox: null,
        resprate: null,
        temperature: null,
        systolic: null,
        diastolic: null
      },
      medicalHistory: [
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        ''
      ],
      medicalHistoryQuestions: [
        "Please state your main medical problem, or health condition requiring today's visit:",
        'Where is your symptom located?',
        'How would you describe the quality of your symptoms?',
        'What is the severity of your symptoms (on a scale of 1-10)?',
        'How long have your symptoms occurred?',
        'When is the timing of your symptoms?',
        'What makes your symptoms better?',
        'What makes your symptoms worse?',
        'Are there any other problems associated with your symptoms?',
        'Please list any past medical conditions',
        'Please list any past surgeries',
        'Do you smoke? If so how many packs a day?',
        'Please enter any family history of medical problems',
        'How much alcohol do you drink in the average week?',
        'Have you used any illicit or street drugs?',
        'Any allergies? List them here'
      ],
      loadingSymptoms: false,
      symptomsHeader: [
        {
          text: 'Name',
          align: 'start',
          sortable: false,
          value: 'symptom'
        },
        { text: 'checked', align: 'end', sortable: false, value: 'checked' }
      ],
      symptoms: [
        {
          category: 'Constitutional',
          symptoms: [
            {
              category: 'Constitutional',
              symptom: 'Weight Loss',
              checked: false
            },
            {
              category: 'Constitutional',
              symptom: 'Night Sweats',
              checked: false
            },
            { category: 'Constitutional', symptom: 'Fatigue', checked: false },
            { category: 'Constitutional', symptom: 'Fever', checked: false },
            { category: 'Constitutional', symptom: 'Rash', checked: false },
            {
              category: 'Constitutional',
              symptom: 'Recent Falls',
              checked: false
            },
            {
              category: 'Constitutional',
              symptom: 'Passing Out',
              checked: false
            }
          ]
        },
        {
          category: 'Eyes',
          symptoms: [
            { category: 'Eyes', symptom: 'Visual Change', checked: false },
            { category: 'Eyes', symptom: 'Headache', checked: false },
            { category: 'Eyes', symptom: 'Eye Pain', checked: false },
            { category: 'Eyes', symptom: 'Double Vision', checked: false },
            { category: 'Eyes', symptom: 'Blind Spots', checked: false },
            { category: 'Eyes', symptom: 'Floaters', checked: false }
          ]
        },
        {
          category: 'Ears, Nose, Mouth, Throat',
          symptoms: [
            {
              category: 'Ears, Nose, Mouth, Throat',
              symptom: 'Runny Nose',
              checked: false
            },
            {
              category: 'Ears, Nose, Mouth, Throat',
              symptom: 'Nose Bleeds',
              checked: false
            },
            {
              category: 'Ears, Nose, Mouth, Throat',
              symptom: 'Ear Pain',
              checked: false
            },
            {
              category: 'Ears, Nose, Mouth, Throat',
              symptom: 'Tooth Ache',
              checked: false
            },
            {
              category: 'Ears, Nose, Mouth, Throat',
              symptom: 'Sore Throat',
              checked: false
            },
            {
              category: 'Ears, Nose, Mouth, Throat',
              symptom: 'Pain Swallowing',
              checked: false
            }
          ]
        },
        {
          category: 'Cardiovascular',
          symptoms: [
            {
              category: 'Cardiovascular',
              symptom: 'Chest Pain',
              checked: false
            },
            {
              category: 'Cardiovascular',
              symptom: 'Unable to Exercise',
              checked: false
            },
            {
              category: 'Cardiovascular',
              symptom: 'Wake up from sleep gasping',
              checked: false
            },
            {
              category: 'Cardiovascular',
              symptom: 'Shortness of breath laying flat',
              checked: false
            },
            {
              category: 'Cardiovascular',
              symptom: 'Leg Swelling',
              checked: false
            },
            {
              category: 'Cardiovascular',
              symptom: 'Rapid Heartbeat',
              checked: false
            }
          ]
        },
        {
          category: 'Respiration',
          symptoms: [
            { category: 'Respiration', symptom: 'Cough', checked: false },
            {
              category: 'Respiration',
              symptom: 'Short of Breath',
              checked: false
            },
            { category: 'Respiration', symptom: 'Wheezing', checked: false },
            { category: 'Respiration', symptom: 'Sputum', checked: false },
            {
              category: 'Respiration',
              symptom: 'Blood in Sputum',
              checked: false
            }
          ]
        },
        {
          category: 'Gastrointestinal',
          symptoms: [
            {
              category: 'Gastrointestinal',
              symptom: 'Abdominal Pain',
              checked: false
            },
            {
              category: 'Gastrointestinal',
              symptom: 'Weight Loss',
              checked: false
            },
            {
              category: 'Gastrointestinal',
              symptom: 'Difficulty Swallowing',
              checked: false
            },
            {
              category: 'Gastrointestinal',
              symptom: 'Indegestion',
              checked: false
            },
            {
              category: 'Gastrointestinal',
              symptom: 'Bloating',
              checked: false
            },
            {
              category: 'Gastrointestinal',
              symptom: 'Loss of Appetite',
              checked: false
            },
            { category: 'Gastrointestinal', symptom: 'Nausea', checked: false },
            {
              category: 'Gastrointestinal',
              symptom: 'Diarrhea',
              checked: false
            },
            {
              category: 'Gastrointestinal',
              symptom: 'Constipation',
              checked: false
            },
            {
              category: 'Gastrointestinal',
              symptom: 'Vomiting Blood',
              checked: false
            },
            {
              category: 'Gastrointestinal',
              symptom: 'Bloody/Dark Stool',
              checked: false
            }
          ]
        },
        {
          category: 'Urological',
          symptoms: [
            { category: 'Urological', symptom: 'Incontinence', checked: false },
            {
              category: 'Urological',
              symptom: 'Pain Urinating',
              checked: false
            },
            {
              category: 'Urological',
              symptom: 'Frequent Urinating',
              checked: false
            },
            {
              category: 'Urological',
              symptom: 'Difficulty Urinating',
              checked: false
            },
            { category: 'Urological', symptom: 'Weak Stream', checked: false }
          ]
        },
        {
          category: 'Musculoskeletal',
          symptoms: [
            {
              category: 'Musculoskeletal',
              symptom: 'Arm or Leg Pain',
              checked: false
            },
            {
              category: 'Musculoskeletal',
              symptom: 'Morning Stiffness',
              checked: false
            },
            {
              category: 'Musculoskeletal',
              symptom: 'End of the Day Stiffness',
              checked: false
            },
            {
              category: 'Musculoskeletal',
              symptom: 'Joint Swelling',
              checked: false
            },
            {
              category: 'Musculoskeletal',
              symptom: 'Difficulty Walking',
              checked: false
            }
          ]
        },
        {
          category: 'Neurological',
          symptoms: [
            {
              category: 'Neurological',
              symptom: 'Change in Sight',
              checked: false
            },
            {
              category: 'Neurological',
              symptom: 'Change in Smell',
              checked: false
            },
            {
              category: 'Neurological',
              symptom: 'Change in Hearing',
              checked: false
            },
            {
              category: 'Neurological',
              symptom: 'Change in Taste',
              checked: false
            },
            { category: 'Neurological', symptom: 'Seizures', checked: false },
            { category: 'Neurological', symptom: 'Headache', checked: false },
            {
              category: 'Neurological',
              symptom: 'Pins and Needles',
              checked: false
            },
            { category: 'Neurological', symptom: 'Numbness', checked: false },
            {
              category: 'Neurological',
              symptom: 'Limb Weakness',
              checked: false
            },
            {
              category: 'Neurological',
              symptom: 'Poor Balance',
              checked: false
            },
            {
              category: 'Neurological',
              symptom: 'Speech Problems',
              checked: false
            }
          ]
        },
        {
          category: 'Psychiatric',
          symptoms: [
            { category: 'Psychiatric', symptom: 'Depression', checked: false },
            {
              category: 'Psychiatric',
              symptom: 'Difficulty Sleeping',
              checked: false
            },
            { category: 'Psychiatric', symptom: 'Anxiety', checked: false },
            {
              category: 'Psychiatric',
              symptom: 'Difficulty Concentrating',
              checked: false
            },
            {
              category: 'Psychiatric',
              symptom: 'Lack of Energy',
              checked: false
            },
            {
              category: 'Psychiatric',
              symptom: 'Excessive Energy',
              checked: false
            }
          ]
        },
        {
          category: 'Hematologic',
          symptoms: [
            {
              category: 'Hematologic',
              symptom: 'Easy Brusing',
              checked: false
            },
            {
              category: 'Hematologic',
              symptom: 'Prolonged Bleeding after Dental Extraction',
              checked: false
            }
          ]
        }
      ],
      loadingDocUpload: false,
      medicalDocImage: null,
      medicalDocName: null,
      medicalDocsCollection: [],
      medicalDocs: [],
      loadingMedicationUpload: false,
      medicationImage: null,
      medicationName: null,
      medicationsCollection: [],
      medicationsDocs: [],
      currentPharmacy: '',
      appointmentInRange: '',
      returnTime: 15
    }
  },
  watch: {
    location: function() {
      if (this.location) {
        this.MapsInit()
        this.service.getPlacePredictions(
          {
            input: this.location
          },
          this.displaySuggestions
        )
      }
    },
    medicalDocsCollection: function() {
      this.medicalDocs = []
      if (this.medicalDocsCollection.length > 0) {
        for (const item of this.medicalDocsCollection) {
          this.medicalDocs.push({ ...item, id: item.id })
        }
      }
    },
    medicationsCollection: function() {
      this.medicationsDocs = []
      for (const item of this.medicationsCollection) {
        this.medicationsDocs.push({ ...item, id: item.id })
      }
    },
    patient: function() {
      this.currentPharmacy = this.patient.medicalcard.pharmacy
      this.$bind(
        'attendees',
        fb.db.collection(
          `Practices/${this.practiceId}/Appointments/${this.appointmentId}/Attendees`
        )
      )
    },
    appointment: function() {
      if (this.appointment) {
        const startTime = this.appointment.startTime.toDate()
        const endTime = this.appointment.endTime.toDate()

        if (
          moment().isBetween(
            moment(startTime).subtract(10, 'minutes'),
            moment(endTime).add(this.returnTime, 'minutes')
          )
        ) {
          this.appointmentInRange = true
        } else {
          this.appointmentInRange = false
        }
      }
    },
    '$route.query': {
      immediate: true,
      handler() {
        if (
          this.$route.query.pid !== null &&
          this.$route.query.pid !== '' &&
          this.$route.query.pid &&
          this.$route.query.p !== null &&
          this.$route.query.p !== '' &&
          this.$route.query.p &&
          this.$route.query.code !== null &&
          this.$route.query.code !== '' &&
          this.$route.query.code
        ) {
          if (this.$route.query.pid === fb.auth.currentUser.uid) {
            this.patientId = this.$route.query.pid
            this.practiceId = this.$route.query.p
            this.appointmentId = this.$route.query.code
          } else {
            console.log('not same user')
          }
        }
      }
    }
  },
  async created() {
    this.$emit(`update:layout`, PatientLayout)

    await this.$bind(
      'appointment',
      fb.db.doc(
        `Practices/${this.practiceId}/Appointments/${this.appointmentId}`
      )
    ).then(async () => {
      this.setAppointment()
      await this.$bind(
        'doctor',
        fb.db.doc(`Proxies/${this.appointment.doctorId}`)
      ).then(() => {
        this.returnTime = this.doctor.returningAppt
      })
    })

    window.setInterval(() => {
      if (this.appointment) {
        const startTime = this.appointment.startTime.toDate()
        const endTime = this.appointment.endTime.toDate()

        if (
          moment().isBetween(
            moment(startTime).subtract(10, 'minutes'),
            moment(endTime).add(this.returnTime, 'minutes')
          )
        ) {
          this.appointmentInRange = true
        } else {
          this.appointmentInRange = false
        }
      }
    }, 1000)

    this.symptomsCollection = await fb.db
      .collection(
        `Practices/${this.practiceId}/Appointments/${this.appointmentId}/Symptoms`
      )
      .get()

    this.getSymptoms()

    this.$bind(
      'medicalDocsCollection',
      fb.db.collection(
        `Practices/${this.practiceId}/Appointments/${this.appointmentId}/Medical Documents`
      )
    )

    this.$bind(
      'medicationsCollection',
      fb.db.collection(
        `Practices/${this.practiceId}/Appointments/${this.appointmentId}/Medications`
      )
    )

    this.$bind('patient', fb.db.doc(`Patients/${this.patientId}`)).then(() => {
      this.currentPharmacy = this.patient.medicalcard.pharmacy
      this.calcPatientHeightWeight()
    })
  },

  methods: {
    finish() {
      this.$router.push(`/room${this.appointment.path}`)
    },
    getSymptoms() {
      for (const item of this.symptomsCollection.docs) {
        let data = item.data()
        let catIndex = this.symptoms.findIndex(e => e.category === item.id)

        for (const key of Object.keys(data)) {
          let index = this.symptoms[catIndex].symptoms.findIndex(
            x => x.symptom === key
          )
          this.symptoms[catIndex].symptoms[index].checked = data[key]
        }
      }
    },
    async setPharmacy(result) {
      try {
        await fb.db
          .doc(`Patients/${this.patientId}`)
          .set(
            {
              medicalcard: {
                pharmacy: result.description
              }
            },
            { merge: true }
          )
          .then(() => {
            this.location = ''
            this.pharmaSearch = false
          })
      } catch (error) {
        console.log(error)
        this.error = 'Sorry, there was an error processing your request.'
      }
    },
    MapsInit() {
      this.service = new window.google.maps.places.AutocompleteService()
    },
    displaySuggestions(predictions, status) {
      if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
        this.searchResults = []
        return
      }
      // this.searchResults = predictions.map(prediction => prediction.description)
      this.searchResults = predictions.map(prediction => prediction)
    },
    async deleteMedicalDoc(file) {
      try {
        fb.db
          .doc(
            `Practices/${this.practiceId}/Appointments/${this.appointmentId}/Medical Documents/${file}`
          )
          .delete()
      } catch (error) {
        console.log(error)
      }
    },
    async deleteMedication(file) {
      try {
        fb.db
          .doc(
            `Practices/${this.practiceId}/Appointments/${this.appointmentId}/Medications/${file}`
          )
          .delete()
      } catch (error) {
        console.log(error)
      }
    },
    prepareMedicalDocImage(file) {
      if (!file) return
      this.medicalDocImage = file

      // this.uploadMedicalDocFile()
    },
    async uploadMedicalDocFile() {
      if (
        this.$refs.docsform.validate() &&
        this.medicalDocName &&
        this.medicalDocImage
      ) {
        if (!this.medicalDocImage) return
        this.loadingDocUpload = true
        const storageRef = fb.storage.ref()
        const photoRef = storageRef.child(
          `appointmentUploads/${this.appointmentId}/medicalDocs/${this.medicalDocImage.name}`
        )
        const uploadTask = photoRef.put(this.medicalDocImage)

        await uploadTask.on(
          'state_changed',
          snapshot => {
            console.log(snapshot.state)
          },
          error => {
            console.log(error)
          },
          async () => {
            if (uploadTask.snapshot.state) {
              const downloadURL = await uploadTask.snapshot.ref.getDownloadURL()

              try {
                await fb.db
                  .collection(
                    `Practices/${this.practiceId}/Appointments/${this.appointmentId}/Medical Documents`
                  )
                  .doc()
                  .set({
                    fileName: this.medicalDocName,
                    url: downloadURL
                  })
              } catch (error) {
                console.log(error)
              } finally {
                this.$refs.medicalDocInput.reset()
                this.medicalDocName = null
                this.medicalDocImage = null

                this.loadingDocUpload = false
              }
            }
          }
        )
      } else {
        this.error = 'All fields required'
      }
    },
    prepareMedicationImage(file) {
      if (!file) return
      this.medicationImage = file

      // this.uploadMedicalDocFile()
    },
    async uploadMedicationFile() {
      if (this.$refs.medsform.validate() && this.medicationImage) {
        if (!this.medicationImage) return
        this.loadingMedicationUpload = true
        const storageRef = fb.storage.ref()
        const photoRef = storageRef.child(
          `appointmentUploads/${this.appointmentId}/medications/${this.medicationImage.name}`
        )
        const uploadTask = photoRef.put(this.medicationImage)

        await uploadTask.on(
          'state_changed',
          snapshot => {
            console.log(snapshot.state)
          },
          error => {
            console.log(error)
          },
          async () => {
            if (uploadTask.snapshot.state) {
              const downloadURL = await uploadTask.snapshot.ref.getDownloadURL()

              try {
                await fb.db
                  .collection(
                    `Practices/${this.practiceId}/Appointments/${this.appointmentId}/Medications`
                  )
                  .doc()
                  .set({
                    fileName: `medication ${moment().format(
                      'YYYY-MM-DD HH:mm:ss.SSSSSS'
                    )}`,
                    url: downloadURL
                  })
              } catch (error) {
                console.log(error)
              } finally {
                this.$refs.medicationInput.reset()
                this.medicationName = null
                this.medicationImage = null

                this.loadingMedicationUpload = false
              }
            }
          }
        )
      } else {
        this.error = 'All fields required'
      }
    },
    async uploadMedication() {
      if (this.medicationName) {
        if (!this.medicationName) return

        try {
          await fb.db
            .collection(
              `Practices/${this.practiceId}/Appointments/${this.appointmentId}/Medications`
            )
            .doc()
            .set({
              fileName: this.medicationName,
              url: ''
            })
        } catch (error) {
          console.log(error)
        } finally {
          this.$refs.medicationInput.reset()
          this.medicationName = null
          this.medicationImage = null
        }
      } else {
        this.error = 'All fields required'
      }
    },
    async saveMedicalHistory(step) {
      try {
        await fb.db
          .doc(
            `Practices/${this.practiceId}/Appointments/${this.appointmentId}`
          )
          .set(
            {
              medicalHistory: this.medicalHistory,
              medicalHistoryQuestions: this.medicalHistoryQuestions
            },
            { merge: true }
          )
        if (step !== -1) {
          this.e1 = step
        }
      } catch (error) {
        console.log(error)
        this.error = error.message
      }
    },
    async saveVitals(step) {
      try {
        await fb.db
          .doc(
            `Practices/${this.practiceId}/Appointments/${this.appointmentId}`
          )
          .set(
            {
              vitals: {
                pulseox: this.vitals.pulseox
                  ? Number(this.vitals.pulseox)
                  : null,
                pulse: this.vitals.pulse ? Number(this.vitals.pulse) : null,
                temperature: this.vitals.temperature
                  ? Number(this.vitals.temperature)
                  : null,
                painscale: this.vitals.painscale
                  ? Number(this.vitals.painscale)
                  : null,
                resprate: this.vitals.resprate
                  ? Number(this.vitals.resprate)
                  : null,
                systolic: this.vitals.systolic
                  ? Number(this.vitals.systolic)
                  : null,
                diastolic: this.vitals.diastolic
                  ? Number(this.vitals.diastolic)
                  : null
              }
            },
            { merge: true }
          )

        await fb.db.doc(`Patients/${this.patientId}`).set(
          {
            medicalcard: {
              height: this.height ? Number(this.height) : null,
              weight: this.weight ? Number(this.weight) : null
            }
          },
          { merge: true }
        )
        if (step !== -1) {
          this.e1 = step
        }
      } catch (error) {
        console.log(error)
        this.error = error.message
      }
    },
    setAppointment() {
      if (this.appointment) {
        const startTime = this.appointment.startTime.toDate()
        const endTime = this.appointment.endTime.toDate()

        if (
          moment().isBetween(
            moment(startTime).subtract(10, 'minutes'),
            moment(endTime).add(this.returnTime, 'minutes')
          )
        ) {
          this.appointmentInRange = true
        } else {
          this.appointmentInRange = false
        }
      }
      this.vitals.painscale = this.appointment.vitals.painscale
        ? this.appointment.vitals.painscale
        : 0
      this.vitals.pulse = this.appointment.vitals.pulse
        ? this.appointment.vitals.pulse
        : null
      this.vitals.pulseox = this.appointment.vitals.pulseox
        ? this.appointment.vitals.pulseox
        : null
      this.vitals.resprate = this.appointment.vitals.resprate
        ? this.appointment.vitals.resprate
        : null
      this.vitals.temperature = this.appointment.vitals.temperature
        ? this.appointment.vitals.temperature
        : null
      this.vitals.systolic = this.appointment.vitals.systolic
        ? this.appointment.vitals.systolic
        : null
      this.vitals.diastolic = this.appointment.vitals.diastolic
        ? this.appointment.vitals.diastolic
        : null

      // for()
      this.medicalHistory =
        this.appointment.medicalHistory.length > 0
          ? this.appointment.medicalHistory
          : ['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '']
      // this.medicalHistoryQuestions = this.appointment.medicalHistoryQuestions ? this.appointment.medicalHistoryQuestions :
    },
    calcPatientHeightWeight() {
      if (this.patient.medicalcard.height) {
        const heightFeet = Math.floor(this.patient.medicalcard.height / 12)
        const heightInches = this.patient.medicalcard.height - heightFeet * 12
        this.heightInches = { inches: `${heightInches} "`, value: heightInches }
        this.heightFeet = { feet: `${heightFeet} '`, value: heightFeet }
      }

      this.weight = this.patient.medicalcard.weight
        ? this.patient.medicalcard.weight
        : null
    },
    async inviteRelative() {
      const relativeInviteEmailNotification = fb.functions.httpsCallable(
        'relativeInviteEmailNotification'
      )

      this.loadingInvite = true
      if (this.$refs.inviteform.validate()) {
        try {
          await relativeInviteEmailNotification({
            email: this.invite.email,
            appointmentDate: `${this.computedStartTime} - ${this.computedEndTime}`,
            appointmentPath: this.appointment.path,
            patientName: `${this.patient.fname} ${this.patient.lname}`,
            relativeName: `${this.invite.fname} ${this.invite.lname}`,
            relativePhoneNumber: `${this.invite.phoneNumber}`
          }).then(async () => {
            await fb.db
              .collection(
                `Practices/${this.practiceId}/Appointments/${this.appointmentId}/Attendees`
              )
              .doc()
              .set({
                fname: this.invite.fname,
                lname: this.invite.lname,
                email: this.invite.email,
                phone: this.invite.phoneNumber
              })
          })
          this.$refs.inviteform.reset()
        } catch (error) {
          this.error = error
          console.log(error)
          this.loadingInvite = false
        }
      } else {
        this.error = 'All Fields Required'
      }
      this.loadingInvite = false
    },
    async updateSymptoms(step) {
      this.loadingSymptoms = true
      let selectedSymptoms = []
      let uncheckedSymptoms = []
      for (const category of this.symptoms) {
        for (const symptom of category.symptoms) {
          if (symptom.checked) {
            selectedSymptoms.push({
              category: symptom.category,
              symptom: symptom.symptom
            })
          } else {
            uncheckedSymptoms.push({
              category: symptom.category,
              symptom: symptom.symptom
            })
          }
        }
      }

      try {
        await fb.db
          .collection(
            `Practices/${this.practiceId}/Appointments/${this.appointmentId}/Symptoms`
          )
          .get()
          .then(snapshot => {
            var batch = fb.db.batch()
            snapshot.forEach(doc => {
              batch.delete(doc.ref)
            })
            batch.commit()
          })
          .then(async () => {
            for (const item of selectedSymptoms) {
              await fb.db
                .collection(
                  `Practices/${this.practiceId}/Appointments/${this.appointmentId}/Symptoms`
                )
                .doc(item.category)
                .set(
                  {
                    [item.symptom]: true
                  },
                  { merge: true }
                )
            }

            for (const item of uncheckedSymptoms) {
              await fb.db
                .collection(
                  `Practices/${this.practiceId}/Appointments/${this.appointmentId}/Symptoms`
                )
                .doc(item.category)
                .set(
                  {
                    [item.symptom]: false
                  },
                  { merge: true }
                )
            }
          })
        if (step !== -1) {
          this.e1 = step
        }
      } catch (error) {
        console.log(error)
        this.error = 'Sorry, there was an error processing your request'
      } finally {
        this.loadingSymptoms = false
      }
    }
  },
  computed: {
    computedStartTime: {
      get() {
        return this.appointment.startTime
          ? moment(this.appointment.startTime.toDate()).format(
              'MMMM D, YYYY hh:mm a'
            )
          : ''
      },
      set() {}
    },
    computedEndTime: {
      get() {
        return this.appointment.endTime
          ? moment(this.appointment.endTime.toDate()).format('hh:mm a')
          : ''
      },
      set() {}
    },
    height: {
      get() {
        return this.heightFeet.value * 12 + this.heightInches.value
      },
      set() {}
    }
  }
}
</script>

<style>
/* .custom-drop .v-expansion-panel-content__wrap {
  padding: 0;
} */
</style>
